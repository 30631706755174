export const defaultFlags = {
  'walk-timer': false,
  'organization-theme': true,
  'training-plan-change-start': false,
  'workouts-swap-days': false,
  'advisor-workout-attachment-photo': false,
  'advisor-workout-history-image-height': false,
  'advisor-workout-history-position': false,
  'demo-live-stream-timestamps': false,
  'onboarding-dob-restriction': true,
  'ask-for-review': false,
  'fue.welcome-video': false,
  'invite-url-qrcode': false,
  'aei.split-message-view': false,
  'sales-tracking-for-commission-enabled': false,
  'advisors-can-be-set-to-refuse-assignments': false,
  'workouts-custom-workout-photo': false,
  'workouts-small-buttons-for-custom-workouts': false,
  'advisor-transphormer-profile-update': false,
  'advisor-transphormer-profile-notes': false,
  'assessment-config-allow-deny-transphormers': false,
  'assessment-config-display-setting-in-menu': false,
  'enforce-scheduled-days-for-assessments': true,
  'assessment-config-display-as-message': false,
  'zoom-image-component--enable-image-download': true,
  'announcements--enable-image-zoom': false,
  'product-url--overwrite-a-aid-flag': true,
  'advisor--update-profile-icon-chat-messages-view': 'default',
  'advisor-profile--show-assignment-options': true,
  'my-profile--display-community-related-profile-settings': false,
  'feed--display-feed-in-menu': false,
  'menu--select-area-where-menu-displays': 'bottom-nav',
  'battle-buddy--show-battle-buddy-banner': false,
  'chat-3--pending-uploads-ui': false,
  'ios-bug--bypass-iap': true,
  'tp--multiple-plans': false,
  'nutrition--enable-food-sharing': false,
  'nutrition--add-food-pictures': false,
  'movements-enable-pr-percentage-view': false,
  'activity--movements-library-button': false,
  'menu--show-subscription-manage': false,
  'subscriptions--tiered': false,
  'chat--enable-new-filter-menu': false,
  'mixpanel--enable': false,
  'transphormer-detail--display-updated-fixed-bar': false,
  'chat--use-new-buttons': false,
  'chat--use-mobile-assessment-button': false,
};
