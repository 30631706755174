export default [
  {
    title: 'Education',
    text: 'Education is a crucial part of making a change plus earning and maintaining results!',
    image: '/assets/photos/onboarding/education.jpg',
    bullets: [
      { title: 'We go live every week day to talk about...' },
      {
        text:
          'Nutrition\n' +
          'Workouts and daily movement\n' +
          'Tips on reaching your goals\n' +
          'Motivation to keep going\n' +
          'What supplements could help you\n',
      },
      {
        text: 'Engage with peers in the 1st Phorm community during the live streams',
      },
    ],
  },
  {
    title: 'Activity',
    text: 'Choose from many workout programs and follow a plan that will help you reach your goals.',
    image: '/assets/photos/onboarding/activity.jpg',
    bullets: [
      { text: 'Stay accountable by tracking your workouts' },
      { text: 'Record your weights and stats to view improvements over time' },
      { text: 'Hit your daily step goal and rank on the leaderboard' },
    ],
  },
  {
    title: 'Nutrition',
    text: 'You can’t manage what you don’t measure. This is the easiest way to measure your food intake.',
    image: '/assets/photos/onboarding/nutrition.jpg',
    bullets: [
      { text: 'Choose between macro counting, portion control, or meal plan' },
      { text: 'Overview of your daily food intake' },
      { text: 'Customize your macro goals' },
      {
        text: 'Easily scan barcodes or search the food database to log your food',
      },
      { text: 'Recipe Builder for nutrition formation of a serving size' },
      { text: 'Create and save your own foods or meal for faster tracking' },
    ],
  },
  {
    title: '1-on-1 Support',
    text: 'Your advisor is here to help guide you towards your specific goal.',
    image: '/assets/photos/onboarding/support.jpg',
    bullets: [
      { title: 'Your advisor can help...' },
      { text: 'Answer questions you have' },
      { text: 'Figure out nutrition improvement for better results' },
      { text: 'Check and evaluate on your assessments' },
      { text: 'Be there for support and accountability' },
    ],
  },
  {
    title: 'Track Progress',
    text: 'You will be able to look back throughout your journey to see the progress you make.',
    image: '/assets/photos/onboarding/progress.png',
    bullets: [
      { text: 'Take progress photos and keep them safe' },
      { text: 'Record your weight +/- progress' },
      { text: 'Save your PRs for your lifts' },
    ],
  },
];
