import { Inject, Injectable, NgZone } from '@angular/core';
import {
  BranchATTAuthorizationStatus,
  BranchDeepLinks,
  BranchInitEvent,
  BranchShowShareSheetParams,
} from 'capacitor-branch-deep-links';
import { NavController, Platform } from '@ionic/angular';
import { RollbarService } from '../../rollbar';
import * as Rollbar from 'rollbar';
import { StorageService } from '../storage.service';
import { UserService } from '../user/user.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { ToastService } from '../toast-service/toast-service.service';
import { Capacitor } from '@capacitor/core';
import { LaunchDarklyService } from '../../modules/launchdarkly/ngx-launchdarkly.service';
import { AppTrackingStatus } from 'capacitor-plugin-app-tracking-transparency/dist/esm/definitions';
import { HttpClient } from '@angular/common/http';
import { apiUrl } from '../../helpers';
import { Clipboard } from '@capacitor/clipboard';
import { ApiData } from '../../interfaces';

@Injectable({
  providedIn: 'root',
})
export class BranchioService {
  private data = null;

  constructor(
    private navCtrl: NavController,
    private ngZone: NgZone,
    private platform: Platform,
    private storageService: StorageService,
    private authenticationService: AuthenticationService,
    private toastService: ToastService,
    private userService: UserService,
    private launchDarkly: LaunchDarklyService,
    private http: HttpClient,
    @Inject(RollbarService) private rollbar: Rollbar,
  ) {}

  /**
   * This function is responsible for checking and handling deep links that are
   * clicked through. Based on the information in the link, we make a decision
   * about what to do with it.
   */
  public async init() {
    // If there is no Cordova, none of this code will work. Get out of here.
    if (!this.platform.is('capacitor')) {
      return;
    }

    // Call out to branch to figure out if there is any data we need to process.
    // We can call this on application resume or launch.
    BranchDeepLinks.addListener('init', (event: BranchInitEvent) => {
      const data = event.referringParams;

      // If there is no Branch-related data, then take no action.
      if (!data['+clicked_branch_link']) {
        return;
      }

      this.data = data;
      this.rollbar.debug('Branch data', data);

      // Store the campaign & referrerId. These would be on an initial link.
      this.storageService.set('campaignId', data.a_id);
      this.storageService.set('referrerId', data.tref_id);

      // If we have a one-time authentication link, process it.
      if (data.otaLink) {
        this.processOtaLink(data.otaLink);
      }

      if (data.page) {
        this.navigateAfterDelay(data.page);
        return;
      }

      if (!!data.custom_string) {
        setTimeout(
          () =>
            this.ngZone.run(() =>
              this.navCtrl.navigateForward('nutrition/custom-food', {
                queryParams: {
                  data: data.custom_string,
                },
              }),
            ),
          100,
        );
      }
    });

    BranchDeepLinks.addListener('initError', error => {
      // If this became a big problem, then we'd need to figure out a better way to do this.
      if (
        error.error &&
        (error.error ===
          'Trouble reaching the Branch servers, please try again shortly.' ||
          error.error.startsWith(
            'Warning. Session initialization already happened.',
          ) ||
          error.error.startsWith(
            'Intra-app linking (i.e. session reinitialization) requires an intent flag',
          ))
      ) {
        return;
      }
      // There are a lot of reasons why branch may or may not initialize.
      this.rollbar.info('branch.io initSession() info', error);
    });
  }

  get advisorId() {
    if (!this.data || !this.data.a_id) {
      return null;
    }

    return this.data.a_id;
  }

  get referrerId() {
    if (!this.data || !this.data.tref_id) {
      return null;
    }

    return this.data.tref_id;
  }

  sendProductPurchase(revenue = 0, description = 'Subscription Purchase') {
    this.rollbar.info('Sending Branch Purchase Event');
    this.sendBranchEvent('PURCHASE', {
      revenue,
      description,
      currency: 'USD',
    });
  }

  sendRegistrationComplete() {
    this.rollbar.info('Sending Branch Complete Registration');
    this.sendBranchEvent('COMPLETE_REGISTRATION');
  }

  sendCompleteTutorial() {
    this.rollbar.info('Sending Branch Complete Tutorial');
    this.sendBranchEvent('COMPLETE_TUTORIAL');
  }

  sendBranchEvent(eventName: string, metaData = {}) {
    if (Capacitor.isNativePlatform()) {
      BranchDeepLinks.sendBranchEvent({ eventName, metaData }).then();
    }
  }

  handleATTAuthorizationStatus(trackingStatus: AppTrackingStatus) {
    if (Capacitor.isNativePlatform() && this.platform.is('ios')) {
      let status: BranchATTAuthorizationStatus;
      // Convert the strings back to the correct integer value.
      switch (trackingStatus) {
        case 'authorized':
          status = 3;
          break;
        case 'denied':
          status = 2;
          break;
        case 'notDetermined':
          status = 0;
          break;
        case 'restricted':
          status = 1;
          break;
      }
      BranchDeepLinks.handleATTAuthorizationStatus({ status });
    }
  }

  share(
    shareText: string = null,
    linkProperties = {},
    analytics: {
      feature?: string;
      campaign?: string;
    } = {
      feature: 'share',
      campaign: 'referral',
    },
  ) {
    if (!Capacitor.isNativePlatform()) {
      this.rollbar.info('share() called with:', {
        shareText,
        linkProperties,
      });
      return;
    }

    of(shareText)
      .pipe(
        switchMap(shareText =>
          shareText
            ? of(shareText)
            : this.launchDarkly.flag('referral--share-text').pipe(take(1)),
        ),
      )
      .pipe(map(flag => flag ?? 'Join the 1st Phorm App'))
      .subscribe(shareText => {
        // These are branch-defined fields which will map to utm_fields.
        if (Capacitor.getPlatform() === 'android') {
          this.generateAndroidUrl(shareText);
        } else {
          BranchDeepLinks.showShareSheet({
            analytics: {
              channel: 'app', // 'utm_source' => 'app',
              feature: 'share', // 'utm_medium'
              campaign: 'referral', // 'utm_campaign'
              tags: [`u:${this.userService.user.id}`],
              ...analytics,
            },
            properties: {
              canonicalIdentifier: `transphormer/refer/${this.userService.user.id}`,
              tref_id: `${this.userService.user.id}`,
              ...linkProperties,
            } as BranchShowShareSheetParams['properties'],
            shareText: shareText || 'Join the 1st Phorm App',
          });
        }
      });
  }

  async shareFood(shareText: string = null, linkProperties = {}) {
    if (!Capacitor.isNativePlatform()) {
      this.rollbar.info('share() called with:', {
        shareText,
        linkProperties,
      });
      return;
    }

    if (Capacitor.getPlatform() === 'android') {
      await BranchDeepLinks.generateShortUrl({
        properties: {
          custom_string: JSON.stringify(linkProperties),
        },
      }).then(res =>
        this.generateAndroidUrlForFoodSharing(shareText + ' ' + res.url),
      );
    } else {
      await BranchDeepLinks.showShareSheet({
        properties: {
          custom_string: JSON.stringify(linkProperties),
        },
        shareText,
      });
    }
  }

  /**
   * Adding a work around until branch fixes their sharesheet for android.
   */
  generateAndroidUrl(shareText: string) {
    this.http
      .get<ApiData<{ url: string }>>(apiUrl('referral-link', 'v2.8'))
      .subscribe(resp => {
        Clipboard.write({
          string: shareText + ' ' + resp.data.url,
        })
          .then(() => {
            this.toastService.flash('Referral URL copied');
          })
          .catch(() => {
            this.rollbar.error(
              '[Referral] - Error copying Android referral URL.',
            );
            this.toastService.flash('Error copying referral URL.');
          });
      });
  }

  /**
   * Adding a work around until branch fixes their sharesheet for android.
   */
  async generateAndroidUrlForFoodSharing(shareText: string) {
    await Clipboard.write({
      string: shareText,
    })
      .then(() => {
        this.toastService.flash('Food URL copied');
      })
      .catch(() => {
        this.rollbar.error('[Nutrition] - Error copying Android food URL.');
        this.toastService.flash('Error copying food URL.');
      });
  }

  private async navigateAfterDelay(page: string) {
    if (this.userService.user) {
      this.rollbar.debug('Rollbar navigation: ' + page);
      setTimeout(
        () => this.ngZone.run(() => this.navCtrl.navigateRoot(page)),
        100,
      );
    }
  }

  private processOtaLink(otaLink: string) {
    // If someone has already logged in, do not log in again!
    if (this.userService.user?.id) {
      this.toastService.flash('User already logged in.').then();
      return;
    }

    // Let the user know we are doing something.
    this.toastService
      .flash({
        duration: 0,
        message: 'Logging in...',
        icon: 'lock-open-outline',
        id: 'logging-in',
        position: 'middle',
      })
      .then();

    return this.authenticationService
      .login(otaLink)
      .pipe(
        catchError(() => {
          // Dismiss, then show our error.
          this.toastService.dismiss();
          this.toastService.flash('Link invalid. Try again.', 8000).then();
          return EMPTY;
        }),
        tap(() => this.toastService.dismiss()),
      )
      .subscribe(() => this.navCtrl.navigateRoot('/dashboard'));
  }
}
